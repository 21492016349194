export const Lookup = {
  "USER_TYPES": [
    {
      "value": "1",
      "text": "register.user_type.employee"
    },
    {
      "value": "2",
      "text": "register.user_type.visitor"
    }
  ],
  "USER_LEARNING_PATHS": [
    {
      "value": "1",
      "text": "course.induction"
    },
    {
      "value": "2",
      "text": "course.decomissioning"
    }
  ],
  "YES_NO": [
    {
      "value": "0",
      "text": "general.no"
    },
    {
      "value": "2",
      "text": "general.yes"
    }
  ],
  "MONTHS": [
    {
      "value": "01",
      "text": "general.month.january"
    },
    {
      "value": "02",
      "text": "general.month.february"
    },
    {
      "value": "03",
      "text": "general.month.march"
    },
    {
      "value": "04",
      "text": "general.month.april"
    },
    {
      "value": "05",
      "text": "general.month.may"
    },
    {
      "value": "06",
      "text": "general.month.june"
    },
    {
      "value": "07",
      "text": "general.month.july"
    },
    {
      "value": "08",
      "text": "general.month.august"
    },
    {
      "value": "09",
      "text": "general.month.september"
    },
    {
      "value": "10",
      "text": "general.month.october"
    },
    {
      "value": "11",
      "text": "general.month.november"
    },
    {
      "value": "12",
      "text": "general.month.december"
    }
  ]
};
