import React, { useEffect, useState } from "react";
import { Table, Button, Input, Container, Row, Col } from "reactstrap";
import { ENDPOINTS, PAGE_SIZE } from "../common/constants";
import { getAccessTokenCookie, parseDateForFrontend, parseDateTimeForFrontend, removeAccessTokenCookie } from "../common/functions";
import moment from "moment";
import DatePicker from "react-datepicker";
import SpinnerComponent from "../components/SpinnerComponent";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory } from "react-router-dom";
import ReactPaginate from 'react-paginate';

export default function Admin() {
  const history = useHistory();
  const [participants, setParticipants] = useState([]);
  const [filters, setFilters] = useState({
    firstLastName: "",
    expiredCourse: null,
    completionDate: null,
    companyName: "",
    pageSize: PAGE_SIZE,
    pageNumber: 1
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalShow, setIsModalShow] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [pages, setPages] = useState(false);
  const [count, setCount] = useState(null);

  useEffect(() => {
    countAndSearch();
  }, [filters.pageNumber]);

  const countAndSearch = () => {
    fetch(ENDPOINTS.PARTICIPANTS_LIST_COUNT + buildFilters(),
      { method: "GET", headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getAccessTokenCookie() } })
      .then(res => res.json())
      .then(data => {
        if (data.result == "OK") {
          setCount(data.data);
          setPages(Math.ceil(Number(data.data) / PAGE_SIZE));
          search();
        } else {
          if (data.httpStatus == 403 && data.result == "NOK") {
            removeAccessTokenCookie();
            history.push("/admin");
          }
        }
      }).catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    fetch(ENDPOINTS.PARTICIPANTS_LIST_COUNT + buildFilters(),
      { method: "GET", headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getAccessTokenCookie() } })
      .then(res => res.json())
      .then(data => {
        if (data.result == "OK") {
          setCount(data.data);
          setPages(Math.ceil(Number(data.data) / PAGE_SIZE));
        } else {
          if (data.httpStatus == 403 && data.result == "NOK") {
            removeAccessTokenCookie();
            history.push("/admin");
          }
        }
      }).catch((err) => {
        console.log(err);
      });
  }, []);

  const resetViewAndFilters = () => {
    setFilters({
      firstLastName: "",
      expiredCourse: null,
      completionDate: null,
      companyName: "",
      pageSize: PAGE_SIZE,
      pageNumber: 1
    });
    setParticipants([]);
    countAndSearch();
  };

  const buildFilters = () => {
    let string = "?";
    Object.keys(filters).forEach((key, i) => {
      if (filters[key] != null) {
        if (key == "completionDate") {
          string += "&" + key + "=" + moment(filters[key], "DD/MM/YYYY").format("YYYY-MM-DD");
        } else {
          string += "&" + key + "=" + filters[key];
        }
      }
    });

    return string;
  };

  const isExpired = (p) => {
    return moment(p.courseExpiryDate).isBefore(moment());
  };

  const printCert = (p) => {
    fetch(ENDPOINTS.DOWNLAOD_CERTIFICATE + "/" + p.guid,
      { method: "GET", headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getAccessTokenCookie() } })
      .then(res => res.blob())
      .then(result => {
        var url = window.URL.createObjectURL(result);
        var link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = 'Certificato_' + p.lastName + " _ " + p.firstName + '.pdf';
        link.hidden = true;
        document.body.appendChild(link);
        link.click();
      });
  };

  const deleteParticipant = () => {
    setIsModalShow(false);

    fetch(ENDPOINTS.DELETE_PARTICIPANT + "/" + idToDelete, { method: "DELETE", headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getAccessTokenCookie() } })
      .then((r) => r.json())
      .then((data) => {
        if (data.result == "OK") {
          setIdToDelete(null);
          resetViewAndFilters();
          countAndSearch();
        } else {
          if (data.httpStatus == 403 && data.result == "NOK") {
            removeAccessTokenCookie();
            history.push("/admin");
          }
        }
      }).catch((err) => {
        console.log(err);
      });
  };

  const search = () => {
    setParticipants([]);
    setIsLoading(true);
    fetch(ENDPOINTS.PARTICIPANTS_LIST + buildFilters(), { method: "GET", headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getAccessTokenCookie() } })
      .then((r) => r.json())
      .then((data) => {
        setIsLoading(false);
        if (data.result == "OK") {
          setParticipants(data.data);
        } else {
          if (data.httpStatus == 403 && data.result == "NOK") {
            removeAccessTokenCookie();
            history.push("/admin");
          }
        }
      }).catch((err) => {
        console.log(err);
      });
  };

  const changePageHandler = (page) => {
    setFilters({ ...filters, pageNumber: page });
    // search();
  };

  const Pagination = () => {
    if (count != null && count > 0 && participants.length > 0) {
      return <ReactPaginate
        containerClassName={"pagination"}
        pageCount={pages}
        onPageChange={(page) => { changePageHandler(page.selected + 1); }}
        marginPagesDisplayed={5}
        pageRangeDisplayed={5}
        activeClassName={"pagination-button-active"}
        pageClassName={"pagination-button-next"}
        forcePage={filters.pageNumber - 1}
        previousLabel={"<"}
        nextLabel={">"}
        previousClassName={"pagination-button-next"}
        nextClassName={"pagination-button-next"}
        previousLinkClassName={"pagination-button-link"}
        nextLinkClassName={"pagination-button-link"}
        pageLinkClassName={"pagination-button-link"}
      />;
    } else {
      return "";
    }
  };

  const downloadCsv = () => {
    fetch(ENDPOINTS.DOWNLOAD_CSV_PARTICIPANTS + buildFilters(),
      { method: "GET", headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getAccessTokenCookie() } })
      .then(res => res.blob())
      .then(result => {
        var url = window.URL.createObjectURL(result);
        var link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = 'report_' + parseDateTimeForFrontend(new Date()) + '.csv';
        link.hidden = true;
        document.body.appendChild(link);
        link.click();
      });
  };

  const renderRow = () => {
    if (participants.length > 0) {
      return participants.map((p) => {
        return (
          <tr key={p.id}>
            <td>{p.firstName}</td>
            <td>{p.lastName}</td>
            <td>{parseDateForFrontend(JSON.parse(p.userMetadata).birthDate)}</td>
            <td>{JSON.parse(p.userMetadata).company}</td>
            <td>{p.completionDate != null ? parseDateTimeForFrontend(p.completionDate) : ""}</td>
            <td>{JSON.parse(p.userMetadata).userType == "1" ? "Lavoratore" : "Visitatore"}</td>
            <td>{p.learningPathName}</td>
            <td>{isExpired(p) ? "SI" : "NO"}</td>
            <td><Button style={{ backgroundColor: "#962033" }} onClick={() => { printCert(p); }}>Stampa</Button></td>
            <td><Button style={{ backgroundColor: "#962033" }} onClick={() => { setIdToDelete(p.id); setIsModalShow(true); }}>Elimina</Button></td>
          </tr>
        );
      });
    } else {
      if (isLoading) {
        return (
          <React.Fragment>
            <tr>
              <td style={{ textAlign: "center" }} colSpan={9}><SpinnerComponent /></td>
            </tr >
          </React.Fragment >
        );
      } else {
        return (
          <React.Fragment>
            <tr style={{ height: "50vh" }}>
              <td style={{ textAlign: "center" }} colSpan={9}>Nessun risultato trovato per i filtri selezionati</td>
            </tr>
          </React.Fragment >
        );
      }
    };
  };


  return (
    <Container style={{ maxWidth: "1200px" }}>
      <h2 className="section-title">Statistiche Partecipanti</h2>
      <SweetAlert
        warning
        title="Attenzione!"
        onConfirm={() => { deleteParticipant(); }}
        onCancel={() => { setIdToDelete(null); setIsModalShow(false); }}
        showCancel
        confirmBtnText="Conferma"
        confirmBtnBsStyle="warning"
        cancelBtnText="Annulla"
        show={isModalShow}
      >
        <span>{"Sei sicuro di voler eliminare il partecipante selezioanto?"}</span>
      </SweetAlert>
      <Row>
        <Col md="12" style={{ "border": "1px solid #000" }}>
          <h4>Ricerca partecipanti</h4>
          <Row>
            <Col>
              <div style={{ display: "inline-flex", width: "100%" }}>
                <div style={{ width: "25%", padding: 5 }}>
                  <Input onChange={(e) => { setFilters({ ...filters, firstLastName: e.target.value }); }}
                    value={filters.firstLastName} placeholder={"Cerca per nome o cognome"}></Input>
                </div>
                <div style={{ width: "25%", padding: 5 }}>
                  <Input onChange={(e) => { setFilters({ ...filters, companyName: e.target.value }); }}
                    value={filters.companyName} placeholder={"Cerca nome azienda"}></Input>
                </div>
                <div style={{ width: "25%", padding: 5 }}>
                  <DatePicker
                    id="date-picker"
                    className="datepicker"
                    placeholderText=" Data completamento"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    value={filters.completionDate}
                    onChange={(date) => { setFilters({ ...filters, completionDate: moment(date).format('DD/MM/YYYY') }); }}
                  />
                </div>
                <div style={{ width: "25%", padding: 5 }}>
                  <Input type="select" name="select-expired" onChange={(e) => { setFilters({ ...filters, expiredCourse: e.target.value }); }} >
                    <option value={null}>Tutti gli stati</option>
                    <option value={false}>NON SCADUTO</option>
                    <option value={true}>SCADUTO</option>
                  </Input>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ display: "inline-flex", width: "100%" }}>
                <div style={{ width: "33%", padding: 5 }}>
                  <Button style={{ backgroundColor: "#962033" }} onClick={() => { countAndSearch(); }}>Cerca</Button>
                  <Button style={{ backgroundColor: "#962033" }} onClick={() => { resetViewAndFilters(); }}>Resetta filtri</Button>
                  {participants.length > 0 ? <Button style={{ backgroundColor: "#962033" }} onClick={() => { (downloadCsv()); }}>Scarica report CSV</Button> : ""}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Cognome</th>
            <th>Data di nascita</th>
            <th>Ditta</th>
            <th>Data superamento corso</th>
            <th>Visitatore/Dipendente</th>
            <th>Tipo corso</th>
            <th>Corso Scaduto</th>
            <th>Stampa Certificato</th>
            <th>Elimina</th>
          </tr>
        </thead>
        <tbody>
          {renderRow()}
        </tbody>
      </Table>
      <Pagination />
    </Container>
  );
};
