import React, { useState } from "react";
import { Button } from "reactstrap";
import { useEffect } from "react";
import VideoPlayer from "react-video-player-extended";
import { ENDPOINTS } from "../common/constants";
import { useGlobalStateContext, setGlobalState } from "../context/GlobalContext";
import SpinnerComponent from "./SpinnerComponent";
import { Dictionary } from "../common/dictionary";

export default function AssetVideo(props) {
  const [videoState, setVideoState] = useState({
    url: null,
    isPlaying: false
  });

  const { pillProgression } = useGlobalStateContext();
  const { participant } = useGlobalStateContext();
  let currentIndex = props.pills.findIndex((p) => { return p.id == pillProgression.currentPillId; });

  useEffect(() => {
    if (props.pill) {
      let payload = {
        s3FilePath: props.pill.s3_path
      };

      setVideoState((prevState) => {
        return { ...prevState, ...{ url: null, isPlaying: false } };
      });

      fetch(ENDPOINTS.PRESIGNED_DOWNLOAD_URL,
        { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(payload) })
        .then((r) => r.json())
        .then((data) => {
          if (data.result = "OK") {
            setVideoState((prevState) => {
              return { ...prevState, ...{ url: data.data.presignedUrl } };
            });
          }
        });
    }
  }, [props.pill]);

  const onProgressCallback = (evt) => {
    if (evt.target.currentTime == evt.target.duration) {
      let tmpProg = { ...pillProgression };
      tmpProg.isComplete = true;
      setGlobalState({ pillProgression: tmpProg });
      setVideoState((prevState) => {
        return { ...prevState, ...{ isPlaying: false } };
      });
    }
  };

  const handlePlayPause = () => {
    setVideoState((prevState) => {
      return { ...prevState, ...{ isPlaying: !videoState.isPlaying } };
    });
  };

  if (props.pill && videoState.url != null) {
    return (
      <>
        <div>
          <h2 className="section-title">{props.pill.title}</h2>
          <VideoPlayer
            url={videoState.url}
            isPlaying={videoState.isPlaying}
            height={"67vh"}
            width={""}
            onProgress={(evt) => { onProgressCallback(evt); }}
            controls={['Progress']}
          />
          <div style={{ marginTop: "20px", textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
            <Button style={{ backgroundColor: "#962033" }} onClick={() => { handlePlayPause(); }}>{(videoState.isPlaying ? Dictionary[participant.language]["general.path.stop"] : Dictionary[participant.language]["general.path.play"])}</Button>
            <Button style={{ backgroundColor: "#962033", display: pillProgression.isComplete ? "inherit" : "none" }} onClick={() => { setGlobalState({ pillProgression: { currentPillId: props.pills[currentIndex + 1].id, isComplete: false } }); }}>{Dictionary[participant.language]["general.path.next"]}</Button>
          </div>
        </div>
      </>
    );
  } else {
    return <SpinnerComponent />;
  }
};
