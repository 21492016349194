import React from "react";
import { Input, InputGroup, Label } from "reactstrap";

export default function FormSingleItem(props) {

  const handleRadioChange = (evt, questionId) => {
    let tempArray = [...props.answers];
    let currentAnswer = tempArray.filter((a) => { return a.questionId == questionId; })[0];
    currentAnswer.value = Number(evt.target.value);
    props.setFormAnswers(tempArray);
  };

  const renderRadioGroup = () => {
    return props.question.options.map((o, i) => {
      return (
        <InputGroup key={props.question.id + "-" + i}>
          <Label check>
            <Input checked={o.value != null && o.value == props.answers.filter((a) => { return a.questionId == props.question.id; })[0].value} name={props.question.id} onChange={(e) => { handleRadioChange(e, props.question.id); }}
              value={o.value} type="radio" />
            {o.text}
          </Label>
        </InputGroup>
      );
    });
  };

  return (
    <div className="question-container">
      <h4 className="question-title">{props.question.id.toString().replace(/^0+/, '') + ". " + props.question.text}</h4>
      <div className="options-container">
        {props.answers ? renderRadioGroup() : ""}
      </div>
    </div>
  );
}
