import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert, Button, Col, Container, FormGroup, Input, Label, Row, UncontrolledAlert } from "reactstrap";
import { ENDPOINTS } from "../common/constants";
import { setAccessTokenCookie } from "../common/functions";

export default function Login() {
    const history = useHistory();
    const [payload, setPayload] = useState({
        username: "",
        password: ""
    });
    const [validationErrors, setValidationErrors] = useState({
        email: false,
        password: false
    });
    const [error, setError] = useState({
        isError: false,
        errorMsg: ""
    });

    const login = () => {
        let tmpErrors = { ...validationErrors };
        let foundError = false;
        for (const [key, value] of Object.entries(payload)) {
            if (value == "" || value == false) {
                foundError = true;
                tmpErrors[key] = true;
            } else {
                tmpErrors[key] = false;
            }
        }

        setValidationErrors(tmpErrors);

        if (!foundError) {
            fetch(ENDPOINTS.LOGIN,
                { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(payload) })
                .then((r) => r.json())
                .then((data) => {
                    setAccessTokenCookie(data.token);
                    history.push("/admin");

                }).catch((err) => {
                    console.log(err);
                    setError({ isError: true, errorMsg: err });
                });
        }
    };

    const renderForm = () => {
        return (
            <>
                <Row style={{ justifyContent: "center" }}>
                    <Col md="8" sm="12">
                        <FormGroup>
                            <Label for="txtLastName">Email/Username</Label>
                            <Input invalid={validationErrors.email} value={payload.username}
                                onChange={(e) => { setPayload({ ...payload, username: e.target.value }); }}
                                type="text" name="txtEmail" id="txtPassword" />
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col md="8" sm="12">
                        <FormGroup>
                            <Label for="txtPassword">Password</Label>
                            <Input invalid={validationErrors.lastName} value={payload.password}
                                onChange={(e) => { setPayload({ ...payload, password: e.target.value }); }}
                                type="password" name="txtPassword" id="txtPassword" />
                        </FormGroup>
                    </Col>
                </Row>
                <Row style={{ justifyContent: "center" }}>
                    <Col md="8" sm="12">
                        <Button style={{ backgroundColor: "#962033", float: "right" }} onClick={() => { login(); }}>Accedi</Button>
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <Container style={{ padding: '30px', marginTop: "20px" }}>
            <h2 className="section-title">Accedi all'Area Amministrativa</h2>
            {error.isError ? <Alert color="danger" isOpen={error.isError}>{error.errorMsg.toString()}</Alert> : ""}
            {renderForm()}
        </Container>
    );
}
