import React from "react";
import { useHistory } from "react-router-dom";
import { Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ENDPOINTS } from "../common/constants";
import { Dictionary } from "../common/dictionary";
import { setGlobalState, useGlobalStateContext } from "../context/GlobalContext";

export default function ResultModal(props) {
    const { participant } = useGlobalStateContext();
    const history = useHistory();

    const downloadCertificate = () => {
        fetch(ENDPOINTS.DOWNLAOD_CERTIFICATE + "/" + participant.guid,
            { method: "GET", headers: { 'Content-Type': 'application/json' } })
            .then(res => res.blob())
            .then(result => {
                var url = window.URL.createObjectURL(result);
                var link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.download = 'Certificato_' + participant.lastName + " _ " + participant.firstName + '.pdf';
                link.hidden = true;
                document.body.appendChild(link);
                link.click();
            });
    };

    const renderButton = () => {
        let button = <>
            <Button style={{ backgroundColor: "#962033" }} onClick={() => { downloadCertificate(); }}>
                {Dictionary[participant.language]["form.result.modal.certificate"]}</Button>
            {/* <Button style={{ backgroundColor: "#962033" }} onClick={() => {
                setGlobalState({
                    language: 'it_IT',
                    projectConfiguration: null,
                    participant: null,
                    pillProgression: {
                        currentPillId: 0,
                        isComplete: false
                    }
                });
                history.push("/");
            }}>{Dictionary[participant.language]["form.result.modal.end"]}</Button> */}
        </>;

        if (props.result.result != 100) {
            if (props.result.result >= 70) {
                button = <Button style={{ backgroundColor: "#962033" }} onClick={() => { props.completeIncorrectAnswers(); props.toggle(false); }}>
                    {Dictionary[participant.language]["form.result.modal.retry"]}</Button>;
            }

            if (props.result.result <= 70) {
                button = <Button style={{ backgroundColor: "#962033" }} onClick={() => { props.restartLearningPath(); props.toggle(false); }}>
                    {Dictionary[participant.language]["form.result.modal.restart"]}</Button>;
            }
        }
        return button;
    };

    const closeModal = () => {
        if (props.result.result == 100) {
            setGlobalState({
                language: 'it_IT',
                projectConfiguration: null,
                participant: null,
                pillProgression: {
                    currentPillId: 0,
                    isComplete: false
                }
            });
            history.push("/");
        }
    }

    const renderText = () => {
        let key = "form.result.pass";
        if (props.result.result != 100) {
            if (props.result.result >= 70) {
                key = "form.result.notpass.medium";
            }

            if (props.result.result <= 70) {
                key = "form.result.notpass.low";
            }
        }

        let resPercentage = props.result.result != null ? props.result.result.toString() : props.result.result;
        return Dictionary[participant.language][key].replace(/_+/, resPercentage);
    };

    return (
        <Modal isOpen={props.open} toggle={null} onClosed={() => {closeModal()}}>
            <ModalHeader>{props.result.result < 100 ? Dictionary[participant.language]["form.result.modal.failed"] : Dictionary[participant.language]["form.result.modal.success"]}</ModalHeader>
            <ModalBody>
                {renderText()}
            </ModalBody>
            <ModalFooter>
                {renderButton()}
            </ModalFooter>
        </Modal>
    );
}
