import React, { useState, useEffect } from "react";
import { useGlobalStateContext } from "../context/GlobalContext";
import { useHistory } from "react-router-dom";
import { Container } from "reactstrap";
import PathSummary from "../components/PathSummary";
import { PILL_TYPE } from "../common/constants";
import AssetVideo from "../components/AssetVideo";
import PillsNavigation from "../components/PillsNavigation";
import FormWrapper from "../components/FormWrapper";
import SpinnerComponent from "../components/SpinnerComponent";
import FrameComponent from "../components/FrameComponent";

export default function Register() {
  const { projectConfiguration } = useGlobalStateContext();
  const { participant } = useGlobalStateContext();
  const { pillProgression } = useGlobalStateContext();
  const history = useHistory();
  const [userPath, setUserPath] = useState(null);

  useEffect(() => {
    if (participant == null || participant.language == null) {
      history.push("/");
    }
    if (projectConfiguration != null && userPath == null) {
      setUserPath(getUserPath());
    }
  }, [userPath]);

  const renderCurrentStage = () => {
    if (pillProgression.currentPillId === 0) {
      return <PathSummary path={userPath} />;
    } else {
      let pill = userPath.pills.filter((p) => { return p.id === pillProgression.currentPillId; })[0];
      if (pill) {
        switch (pill.type) {
          case PILL_TYPE.VIDEO:
            return <AssetVideo pills={userPath.pills} pill={pill} />;
          case PILL_TYPE.EMBEDDED:
            return <FrameComponent pills={userPath.pills} pill={pill} />;
          case PILL_TYPE.TEST:
            return <FormWrapper pills={userPath.pills} pill={pill} />;
        }
      }
    };
  };

  const getUserPath = () => {
    let paths = projectConfiguration.learning_paths;
    let el = null;
    paths.forEach((p, i) => {
      let auths = p.authorizations;
      let isEnabled = true;
      auths.forEach((item, j) => {
        let property = item.property;
        let value = item.value;

        if (isEnabled && participant[property] !== value) {
          isEnabled = false;
        }
      });
      if (isEnabled)
        el = p;
    });
    return el;
  };

  if (participant != null && userPath != null) {
    return (
      <>
        <Container>
          {renderCurrentStage()}
          <PillsNavigation pills={userPath.pills} />
        </Container>
      </>
    );
  }
  return <SpinnerComponent />;
};
