import React from "react";

export default function Header() {
  return (
    <div className="header">
      <img className="header-logo_image" alt="" src={"/img/abbicura.jpg"} />
      <div className="header-center ">
        <img alt="" src={"/img/formazione_sicurezza.jpg"} />
      </div>
      <div className="header-right">
        <img className="header-logo_image" alt="" src={"/img/logo.jpg"} />
      </div>
    </div>
  );
}
