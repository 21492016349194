import React, { useEffect, useCallback } from "react";
import { Route, Switch, HashRouter, useHistory } from "react-router-dom";
import Header from "../components/Header";
import { EMCS_RESOURCES_URL, PROJECT_GUID } from "../common/constants";
import { getAccessTokenCookie } from "../common/functions";
import LanguageChoice from "./LanguageChoice";
import Register from "./Register";
import Content from "./Content";
import Admin from "./Admin";
import { setGlobalState, useGlobalStateContext } from "../context/GlobalContext";
import SpinnerComponent from "../components/SpinnerComponent";
import Login from "./Login";

export default function Proxy() {
  const { projectConfiguration } = useGlobalStateContext();

  const loadProjectConfiguration = useCallback(() => {
    let projectConfigurationUrl = EMCS_RESOURCES_URL + "/projects/" + PROJECT_GUID + "/project_configuration.json";

    fetch(projectConfigurationUrl, { method: "GET" })
      .then((r) => r.json())
      .then((data) => {
        setGlobalState({ projectConfiguration: data });
      });
  });

  useEffect(() => {
    if (projectConfiguration == null) {
      loadProjectConfiguration();
    }
  }, [projectConfiguration]);

  const PrivateRoute = ({ component: Component, ...rest }) => {
    if (rest.location.pathname == "/admin") {
      return (
        <Route {...rest} exact render={(props) => (
          (getAccessTokenCookie() != null && getAccessTokenCookie() != undefined)
            ? <Admin {...props} />
            : <Login {...props} />
        )} />
      );
    } else {
      return (
        <Route {...rest} exact render={(props) => (
          projectConfiguration != null
            ? projectConfiguration.available_languages.length > 1
              ? <LanguageChoice {...props} />
              : <Register {...props} />
            : <SpinnerComponent />
        )} />
      );
    }
  };

  return (
    <>
      <Header />
      <div id="container" className="main-container">
        <HashRouter>
          <Switch>
            <Route exact path="/content" component={Content} />
            <Route exact path="/register" component={Register} />
            <PrivateRoute path="/" />
          </Switch>
        </HashRouter>
      </div>
    </>
  );
}
