import React, { useState, useEffect } from "react";
import { setGlobalState, useGlobalStateContext } from "../context/GlobalContext";
import { Container, Row, Col, FormGroup, Label, Input, Button, Alert, UncontrolledAlert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Dictionary } from "../common/dictionary";
import { Lookup } from "../common/lookup";
import DatePicker from "../components/DatePicker";
import { EMCS_RESOURCES_URL, ENDPOINTS, PROJECT_GUID, REGEX } from "../common/constants";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

export default function Register() {
  const [partState, setPartState] = useState({ firstName: "", lastName: "", pin_code: "", company: "", user_type: "", birth_date: "", privacyConsent: false, user_learning_path: "" });
  const [contact, setContact] = useState({
    type: "",
    detail: "",
    detail_label: "",
    completeInfo: false
  });
  const [validationErrors, setValidationErrors] = useState({ firstName: false, lastName: false, pin_code: false, company: false, user_type: false, birth_date: false, privacyConsent: false, detail: false });
  const [pinRequested, setPinRequested] = useState(false);
  const [error, setError] = useState(null);
  const { projectConfiguration } = useGlobalStateContext();
  const { participant } = useGlobalStateContext();
  const history = useHistory();

  useEffect(() => {
    if (participant == null || participant.language == null) {
      history.push("/");
    }
    if (participant != null && participant.firstName != null && participant.firstName !== "") {
      history.push("/content");
    }
  });

  const populateUserObject = (property, value) => {
    let tmp = { ...partState };
    tmp[property] = value;
    setPartState(tmp);
  };

  const changeContactDetail = (property, value) => {
    let tmp = { ...contact };
    tmp[property] = value;
    if (property === "type" && value === "1") {
      tmp["detail"] = "";
      setValidationErrors({ ...validationErrors, detail: false });
      tmp["detail_label"] = "register.contact.type.mail";
    } else if (property === "type" && value === "2") {
      tmp["detail"] = "";
      setValidationErrors({ ...validationErrors, detail: false });
      tmp["detail_label"] = "register.contact.type.cell_phone";
    }

    if (tmp["detail"] !== "" && tmp["type"] !== "") {
      if (tmp.type == "1") {
        setValidationErrors({ ...validationErrors, detail: !REGEX.EMAIL.test(tmp["detail"]) });
      }
      // } else {
      //     setValidationErrors({ ...validationErrors, detail: REGEX.PHONE.test(tmp["detail"]) });;
      // }

      let tmpPartState = { ...partState };
      tmpPartState["contact"] = tmp;
      setPartState(tmpPartState);

      tmp["completeInfo"] = true;

    } else {
      tmp["completeInfo"] = false;
    }

    setContact(tmp);

  };

  const renderField = (f) => {
    let field = null;
    switch (f.type) {
      case "text":
        field = <Input invalid={validationErrors[f.id]} onChange={event => populateUserObject(f.id, event.target.value)} type="text" name={f.id} id={f.id} />;
        break;
      case "single":
        let opts = Lookup[f.domain].map((op) => {
          return <option key={op.value} value={op.value}>{Dictionary[participant.language][op.text]}</option>;
        });
        field = <Input invalid={validationErrors[f.id]} onChange={event => populateUserObject(f.id, event.target.value)} type="select" id={f.id}><option value=""></option>{opts}</Input>;
        break;
      case "date":
        field = <DatePicker invalid={validationErrors[f.id]} onComplete={populateUserObject} id={f.id} language={participant.language} />;
        break;
      default:
        break;
    }

    return field;
  };

  const sendPin = () => {
    if (validationErrors.detail == false) {
      let payload = {
        notificationType: partState.contact.type,
        contactDetail: partState.contact.type == "2" ? "+" + partState.contact.detail : partState.contact.detail,
        projectGuid: PROJECT_GUID
      };
      fetch(ENDPOINTS.SEND_PIN,
        { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(payload) })
        .then((r) => r.json())
        .then((data) => {
          if (data.result == "OK") {
            setPinRequested(true);
          } else {
            setError(data.message);
          }
        });
    }
  };

  const formValidation = () => {
    let foundError = false;

    let learningPathName = "";
    if (partState.user_learning_path!=null&&partState.user_learning_path!=="") {
      learningPathName = Lookup.USER_LEARNING_PATHS.filter(lp => lp.value===partState.user_learning_path);
      if (learningPathName!=null) {
        learningPathName = Dictionary[participant.language][learningPathName[0].text];
      }
    }

    console.log(learningPathName);

    let payload = {
      projectGuid: projectConfiguration.project_guid,
      firstName: partState.firstName,
      lastName: partState.lastName,
      privacyConsent: partState.privacyConsent,
      email: contact.detail_label == "register.contact.type.mail" ? contact.detail : null,
      phone: contact.detail_label == "register.contact.type.cell_phone" ? contact.detail : null,
      language: participant.language,
      pin: partState.pin_code,
      companyName: partState.company,
      learningPathName: learningPathName,
      participantType: partState.user_type,
      userMetadata: JSON.stringify({
        userType: partState.user_type,
        birthDate: partState.birth_date,
        company: partState.company,
        learningPath: partState.user_learning_path
      })
    };

    let tmpErrors = { ...validationErrors };

    for (const [key, value] of Object.entries(partState)) {
      if (value == "" || value == false) {
        foundError = true;
        tmpErrors[key] = true;
      } else {
        tmpErrors[key] = false;
      }
    }

    setValidationErrors(tmpErrors);

    if (!foundError) {
      fetch(ENDPOINTS.USER_REGISTER,
        { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(payload) })
        .then((r) => r.json())
        .then((data) => {
          if (data.result == "OK") {
            let globalPart = { ...participant };
            let tmp = partState;
            tmp.language = globalPart.language;
            tmp.guid = data.data;
            setGlobalState({ participant: tmp });
            history.push("/content");
          } else {
            setError(data.message);
          }
        });
    }
  };


  const createForm = () => {
    if (projectConfiguration != null) {
      return projectConfiguration.user_profile.map((l) => {
        return (
          <Col md="6" key={l.id}>
            <FormGroup>
              <Label for="txtFirstName">{Dictionary[participant.language][l.label]}</Label>
              {renderField(l)}
            </FormGroup>
          </Col>
        );
      });
    }
  };

  const renderForm = () => {
    return (
      <>
        <Row>
          <Col md="6" sm="12">
            <FormGroup>
              <Label for="txtFirstName">{Dictionary[participant.language]["register.first_name"]}</Label>
              <Input invalid={validationErrors.firstName} value={partState.firstName} onChange={event => populateUserObject("firstName", event.target.value)} type="text" name="txtFirstName" id="txtFirstName" />
            </FormGroup>
          </Col>
          <Col md="6" sm="12">
            <FormGroup>
              <Label for="txtLastName">{Dictionary[participant.language]["register.last_name"]}</Label>
              <Input invalid={validationErrors.lastName} value={partState.lastName} onChange={event => populateUserObject("lastName", event.target.value)} type="text" name="txtLastName" id="txtLastName" />
            </FormGroup>
          </Col>
          {createForm()}
        </Row>
        <Row className="privacy-check">
          <Col md="12" sm="12">
            <FormGroup check>
              <Label check for="privacyCheck">
                <Input className={validationErrors.privacyConsent ? "privacy-check-error" : "form-check-input"} style={{ border: validationErrors.privacyConsent ? "1px solid red !important" : "unset" }} id="privacyCheck" checked={partState.privacyConsent} onChange={() => { populateUserObject("privacyConsent", !partState.privacyConsent); }} type="checkbox" />{' '}
                <a className={validationErrors.privacyConsent ? "privacy-text-error" : "privacy-text"} style={{ fontSize: "14px" }} href={EMCS_RESOURCES_URL + "/projects/" + PROJECT_GUID + "/informativa_privacy_" + participant.language + ".pdf"} target="_blank">{Dictionary[participant.language]["register.privacy"]}</a>
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Button style={{ "marginTop": "20px", backgroundColor: "#962033" }} onClick={(e) => { formValidation(e); }}>{Dictionary[participant.language]["register.save"]}</Button>
          </Col>
        </Row>
      </>
    );
  };

  if (participant != null) {
    return (
      <>
        {error != null ? <UncontrolledAlert color="danger">{error}</UncontrolledAlert> : ""}
        <Container>
          <Row>
            <Col md="12">
              <h1 className="section-title">{Dictionary[participant.language]["register"]}</h1>
              <Row style={{ "display": (pinRequested ? "none" : "block") }}>
                <Col md="12" style={{ "border": "1px solid #000" }}>
                  <h4>{Dictionary[participant.language]["register.contact.title"]}</h4>
                  <Row>
                    <Col md="4" sm="12">
                      <FormGroup>
                        <Label>{Dictionary[participant.language]["register.contact.type"]}</Label>
                        <Input value={contact.type} onChange={e => changeContactDetail("type", e.target.value)} type="select" name="ddlTipoContatto" id="ddlTipoContatto">
                          <option value=""></option>
                          <option value="1">{Dictionary[participant.language]["register.contact.type.mail"]}</option>
                          <option value="2">{Dictionary[participant.language]["register.contact.type.cell_phone"]}</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col md="4" sm="12">
                      <FormGroup style={{ "display": (contact.type !== "" ? "block" : "none") }}>
                        <Label>{Dictionary[participant.language][contact.detail_label]}</Label>
                        {contact.type == "2"
                          ? <PhoneInput
                            // inputClass={contact.detail.length > 0 ? !validationErrors.detail ? "form-control-error" : "form-control" : "form-control"}
                            country={'it'}
                            countryCodeEditable={false}
                            value={contact.detail}
                            onChange={phone => { changeContactDetail("detail", phone); }}
                          />
                          : <Input type="text" onChange={e => changeContactDetail("detail", e.target.value)} name="txtInfoMail" id="txtInfoMail"></Input>
                        }

                      </FormGroup>
                    </Col>
                    <Col md="4" sm="12">
                      <FormGroup>
                        <Label>&nbsp;</Label>
                        <Button style={{ "display": (contact.completeInfo ? "block" : "none"), backgroundColor: "#962033" }} onClick={() => { sendPin(); }}>{Dictionary[participant.language]["register.send_pin_code"]}</Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ "display": (!pinRequested ? "none" : "block") }}>
                <Col md="12" style={{ "border": "1px solid #000" }}>
                  <h4>{Dictionary[participant.language]["register.contact.pin_insert.title"]}</h4>
                  <Row>
                    <Col md="4" sm="12">
                      <FormGroup>
                        <Label for="txtPin">{Dictionary[participant.language]["register.pin_code"]}</Label>
                        <Input invalid={validationErrors.pin_code} onChange={event => populateUserObject("pin_code", event.target.value)} type="text" name="txtPin" id="txtPin" />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {pinRequested ? renderForm() : ""}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
  return "";
}
