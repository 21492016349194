import React, { useEffect } from "react";
import { DEBUG, ENDPOINTS } from "../common/constants";
import { setGlobalState, useGlobalStateContext } from "../context/GlobalContext";
import { useState } from "react";
import FormSingleItem from "./FormSingleItem";
import SpinnerComponent from "./SpinnerComponent";
import { Button } from "reactstrap";
import ResultModal from "./ResultModal";
import { Dictionary } from "../common/dictionary";

export default function FormWrapper(props) {

  const [formQuestions, setFormQuestions] = useState(null);
  const [formAnswers, setFormAnswers] = useState(null);
  const [completed, setCompleted] = useState(false);
  const [result, setResult] = useState({ result: null, missingQuestions: [] });
  const [showResModal, setShowResModal] = useState(false);
  const [fullFormAnswers, setFullFormAnswers] = useState(null);
  const [fullQuestions, setFullQuestions] = useState(null);
  const { participant } = useGlobalStateContext();

  useEffect(() => {
    if (props.pill) {
      let payload = {
        s3FilePath: props.pill.s3_path
      };

      fetch(ENDPOINTS.PRESIGNED_DOWNLOAD_URL, { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(payload) })
        .then((r) => r.json())
        .then((data) => {
          if (data.result = "OK") {
            fetch(data.data.presignedUrl, {
              method: "GET", headers: { 'Content-Type': 'application/json' }
            })
              .then((r) => r.json())
              .then((data) => {
                let tempAnswers = [];
                data.questions.map((q) => {
                  let correctValue = q.options.filter((o) => { return o.correct == 1; })[0].value;
                  tempAnswers.push({
                    value: null,
                    correct: correctValue,
                    questionId: q.id
                  });
                });
                setFormAnswers(tempAnswers);
                setFormQuestions(data);
                setFullQuestions(data);
              });
          }
        });
    }
  }, [props.pill]);

  useEffect(() => {
    if (formAnswers != null && formAnswers.length > 0) {
      let completed = formAnswers.every((el) => el.value != null);
      if (completed) {
        setCompleted(completed);
      }
    }
  }, [formAnswers]);

  const renderItems = () => {
    return formQuestions.questions.map((q) => {
      switch (q.type) {
        case "SINGLE":
          return <FormSingleItem key={q.id} setFormAnswers={setFormAnswers} answers={formAnswers} question={q} />;
      }
    });
  };

  const completeIncorrectAnswers = () => {
    let temp = [...formAnswers];
    let formQuestTemp = { ...formQuestions };

    setFullQuestions(formQuestions);
    setFullFormAnswers(temp);
    setCompleted(false);

    formQuestTemp.questions = [];

    temp.forEach((a) => {
      if (Number(a.value) != Number(a.correct)) {
        a.value = null;
        formQuestions.questions.map((q) => {
          if (a.questionId.toString() == q.id) {
            return formQuestTemp.questions.push(q);
          }
        });
      }
      return a;
    });

    setFormQuestions(formQuestTemp);
    setFormAnswers(temp);
  };

  const restartLearningPath = () => {
    setGlobalState({
      pillProgression: {
        currentPillId: 0,
        isComplete: false
      }
    });
  };

  const endQuiz = () => {
    let percentageRes = ((formAnswers.filter((a) => { return Number(a.value) == Number(a.correct); }).length * 100) / (formAnswers.length)).toFixed();
    // if (DEBUG) {
    // percentageRes = 100;
    // }
    let res = {};
    res.missingQuestions = [...formAnswers.filter((a) => { return Number(a.value) != Number(a.correct); })];
    if (percentageRes == 100) {
      let payload = {
        participantGuid: participant.guid,
        trackingData: JSON.stringify({
          participant: participant,
          result: percentageRes,
          answers: formAnswers
        })
      };

      fetch(ENDPOINTS.COMPLETE_PATH, { method: "POST", headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(payload) })
        .then((r) => r.json())
        .then((data) => {
          if (data.result != "OK") {
            // setError(data.message);
          }
        });
    }
    res.result = percentageRes;
    setResult(res);
    setShowResModal(true);
  };

  if (formQuestions) {
    return (
      <div className="form-container">
        <ResultModal open={showResModal} toggle={setShowResModal} result={result}
          completeIncorrectAnswers={completeIncorrectAnswers} restartLearningPath={restartLearningPath} />
        <h2>{props.pill.text}</h2>
        <div className="form-container-items">
          {renderItems()}
        </div>
        <div className="footer-controls">
          <Button style={{ backgroundColor: "#962033" }} disabled={!completed} onClick={() => { endQuiz(); }}>{Dictionary[participant.language]["form.end"]}</Button>
        </div>
      </div>
    );
  } else {
    return <SpinnerComponent />;
  }
};
