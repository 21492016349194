import React, { useState } from "react";
import { Input } from "reactstrap";
import { Dictionary } from "../common/dictionary";
import { Lookup } from "../common/lookup";
import moment from 'moment';
import { useEffect } from "react";

export default function DatePicker(props) {
  const [value, setValue] = useState({});
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    setInvalid(props.invalid);
  }, [props.invalid]);

  const populateDatePart = (part, partValue) => {
    let tmp = { ...value };

    switch (part) {
      case "dd":
        if (partValue.length <= 2 && partValue <= 31) {
          tmp[part] = partValue;
        }
        break;

      case "mm":
        tmp[part] = partValue;
        break;

      case "yyyy":
        if ((partValue.length <= 4 && partValue <= moment().year())) {
          tmp[part] = partValue.replace(/\D/g, '');
        } else {
          tmp[part] = moment().year().toString();
        }
        break;

      default:
        break;
    }


    setValue(tmp);
    if (tmp["dd"] != null && tmp["mm"] != null && tmp["yyyy"] != null) {
      let finalDate = tmp["yyyy"] + "-" + tmp["mm"] + "-" + tmp["dd"];
      if (!moment(finalDate).isValid() || tmp["yyyy"].length != 4) {
        setInvalid(true);
        return;
      }
      setInvalid(false);
      props.onComplete(props.id, moment(finalDate).format("YYYY-MM-DD"));
    }
  };

  let optsMonth = Lookup["MONTHS"].map((op) => {
    return <option key={op.value} value={op.value}>{Dictionary[props.language][op.text]}</option>;
  });

  return (
    <div className="form-inline">
      <Input invalid={invalid} min={1} max={31} value={value["dd"]} type="number" onChange={event => populateDatePart("dd", event.target.value)} className="class-day form-control w-25" name="txtDateDay" id="txtDateDay" placeholder={Dictionary[props.language]["general.day"]} />
      <Input invalid={invalid} value={value["mm"]} type="select" onChange={event => populateDatePart("mm", event.target.value)} className="class-month form-control w-50" defaultValue="" name="txtDateMonth" id="txtDateMonth"><option value="" disabled> {Dictionary[props.language]["general.month"]} </option>{optsMonth}</Input>
      <Input invalid={invalid} min={1900} max={moment().year()} value={value["yyyy"]} type="number" onChange={event => populateDatePart("yyyy", event.target.value)} className="class-year form-control w-25" name="txtDateYear" id="txtDateYear" placeholder={Dictionary[props.language]["general.year"]} />
    </div>
  );
};
