import { Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import { setGlobalState, useGlobalStateContext } from "../context/GlobalContext";
import SpinnerComponent from "./SpinnerComponent";
import { Dictionary } from "../common/dictionary";
import { DEBUG } from "../common/constants";

export default function FrameComponent(props) {
  const { pillProgression } = useGlobalStateContext();
  const { participant } = useGlobalStateContext();
  let currentIndex = props.pills.findIndex((p) => { return p.id == pillProgression.currentPillId; });

  useEffect(() => {
    // if (DEBUG) {
    //   let tmpProg = { ...pillProgression };
    //   tmpProg.isComplete = true;
    //   setGlobalState({ pillProgression: tmpProg });
    // } else {
    window.addEventListener("message", receiveMessage, false);
    function receiveMessage(e) {
      try {
        let tmpProg = { currentPillId: props.pill.id, isComplete: JSON.parse(e.data).status == "completed" };
        setGlobalState({ pillProgression: tmpProg });
        window.removeEventListener("message", receiveMessage, false);
      } catch (e) {
        console.log(e);
      }
    }
    // }
  }, [props.pill]);

  if (props.pill != null) {
    return (
      <>
        <div>
          <h2 className="section-title">{props.pill.title}</h2>
          <div style={{ height: "65vh", marginTop: "20px", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
            <iframe className="iframe-captivate" src={process.env.PUBLIC_URL + "/pills/" + props.pill.s3_path}></iframe>
          </div>
          <div style={{ marginTop: "20px", textAlign: "center", display: "flex", width: "100%", justifyContent: "center", alignItems: "center" }}>
            {DEBUG === true
              ? <Button style={{ backgroundColor: "#962033" }} onClick={() => { setGlobalState({ pillProgression: { currentPillId: props.pills[currentIndex + 1].id, isComplete: false } }); }}>{Dictionary[participant.language]["general.path.next"]}</Button>
              : <Button style={{ backgroundColor: "#962033", display: pillProgression.isComplete ? "inherit" : "none" }} onClick={() => { setGlobalState({ pillProgression: { currentPillId: props.pills[currentIndex + 1].id, isComplete: false } }); }}>{Dictionary[participant.language]["general.path.next"]}</Button>
            }
          </div>
        </div>
      </>
    );
  } else {
    return <SpinnerComponent />;
  }
}
