export const Dictionary = {
  "it_IT": {
    "course.induction": "Induction",
    "course.decomissioning": "Decomissioning",
    "register": "Registrazione utente",
    "register.first_name": "Nome",
    "register.pin_code": "Codice PIN",
    "register.user_learning_path": "Percorso formativo",
    "register.last_name": "Cognome",
    "register.user_type": "Tipologia utente",
    "register.company_name": "Ditta",
    "register.user_type.employee": "Lavoratore",
    "register.user_type.visitor": "Visitatore",
    "register.birth_nation": "Nazione di nascita",
    "register.birth_date": "Data di nascita",
    "register.activity": "Attività che si viene a svolgere in centrale",
    "register.referral": "Referente",
    "register.visit_reason": "Motivo visita",
    "register.video_already_seen": "Hai già visto il video?",
    "register.save": "Salva i dati e procedi con la formazione",
    "register.contact.title": "Ricevi il codice di accesso per proseguire con l'attività",
    "register.contact.type": "Tipo contatto",
    "register.contact.type.mail": "Indirizzo E-Mail",
    "register.contact.type.cell_phone": "Numero di telefono",
    "course.summary": "Sommario del corso",
    "register.send_pin_code": "Inviami il codice d'accesso",
    "general.user": "Utente",
    "general.yes": "Sì",
    "general.no": "No",
    "general.month.january": "Gennaio",
    "general.month.february": "Febbraio",
    "general.month.march": "Marzo",
    "general.month.april": "Aprile",
    "general.month.may": "Maggio",
    "general.month.june": "Giugno",
    "general.month.july": "Luglio",
    "general.month.august": "Agosto",
    "general.month.september": "Settembre",
    "general.month.october": "Ottobre",
    "general.month.november": "Novembre",
    "general.month.december": "Dicembre",
    "general.day": "Giorno",
    "general.month": "Mese",
    "general.year": "Anno",
    "general.pin_modal.title": "Verifica del PIN di accesso",
    "register.contact.pin_insert.title": "Inserisci il PIN che hai ricevuto (via e-mail o SMS a seconda della tua scelta)",
    "general.pin_modal.description": "Inserisci il PIN che hai ricevuto (via e-mail o SMS a seconda della tua scelta) e clicca su \"Verifica\".",
    "general.pin_modal.verify": "Verifica",
    "general.path.start": "Inizia il corso",
    "general.path.play": "Avvia la riproduzione",
    "general.path.stop": "Ferma la riproduzione",
    "general.path.next": "Avanti",
    "general.path.start": "Inizia corso",
    "form.result.pass": "Hai superato il test finale, ora puoi scaricare il certificato per accedere in Centrale",
    "form.result.notpass.medium": "Hai ottenuto una percentuale del _% di risposte corrette. Per superare il test devi ottenere una percentuale del 100%, pertanto dovrai rispondere nuovamente alle domande sbagliate",
    "form.result.notpass.low": "Hai ottenuto una percentuale inferiore al 70% di risposte esatte, dovrai rivedere l'intero corso ed effettuare nuovamente il test finale",
    "form.result.modal.retry": "Riprova",
    "form.result.modal.certificate": "Visualizza/stampa certificato",
    "form.result.modal.restart": "Ricomincia",
    "form.result.modal.failed": "Test non superato",
    "form.result.modal.success": "Test superato",
    "form.end": "Termina test",
    "register.privacy": "Dichiaro di aver letto con attenzione l'informativa sulla privacy resa ai sensi della normativa privacy nazionale e dell’art. 13 GDPR 679/2016 *e",
    "form.result.modal.end": "Concludi corso"
  },
  "en_GB": {
    "course.induction": "Induction",
    "course.decomissioning": "Decomissioning",
    "register.user_learning_path": "Learning path",
    "register": "User registration",
    "register.first_name": "First name",
    "register.pin_code": "PIN Code",
    "register.last_name": "Last name",
    "register.user_type": "User type",
    "register.company_name": "Company",
    "register.user_type.employee": "Worker",
    "register.user_type.visitor": "Visitor",
    "register.birth_nation": "Birth nation",
    "register.birth_date": "Birth date",
    "register.activity": "Activity to be done in central",
    "register.referral": "Internal referrer",
    "register.visit_reason": "Visit reason",
    "register.video_already_seen": "Did you watched the video previously?",
    "register.save": "Save and proceed with the learning path",
    "register.contact.title": "Please specify your contact info to get the PIN code to continue",
    "register.contact.pin_insert.title": "Please insert the PIN code you just received (by e-mail or SMS depending on your choice in the previous step)",
    "register.contact.type": "Contact type",
    "register.privacy": "I declare that I have carefully read the privacy policy provided pursuant to the national privacy legislation and art. 13 GDPR 679/2016 * e",
    "register.send_pin_code": "Send me the pin code",
    "register.contact.type.mail": "E-Mail address",
    "register.contact.type.cell_phone": "Cell phone",
    "course.summary": "Course summary",
    "general.user": "User",
    "general.yes": "Yes",
    "general.no": "No",
    "general.month.january": "January",
    "general.month.february": "February",
    "general.month.march": "March",
    "general.month.april": "April",
    "general.month.may": "May",
    "general.month.june": "June",
    "general.month.july": "July",
    "general.month.august": "August",
    "general.month.september": "September",
    "general.month.october": "October",
    "general.month.november": "November",
    "general.month.december": "December",
    "general.day": "Day",
    "general.month": "Month",
    "general.year": "Year",
    "general.pin_modal.title": "Verify access PIN",
    "general.pin_modal.description": "Please insert the PIN code you just received (by e-mail or SMS depending on your choice in the previous step) and click on \"Verify\" button.",
    "general.pin_modal.verify": "Verify",
    "general.path.start": "Start learning path",
    "general.path.play": "Play",
    "general.path.stop": "Stop",
    "general.path.next": "Next",
    "form.result.pass": "You passed the test, now you can download or print your rating certificate.",
    "form.result.notpass.medium": "You scored a percentage of _% correct answers. To pass the test you need to get a percentage of 100%, so you will have to answer the wrong questions again",
    "form.result.notpass.low": "You scored less than 70% correct answers, you will need to re-attend the entire course and take the final test again",
    "form.result.modal.retry": "Retry",
    "form.result.modal.certificate": "View/print certificate",
    "form.result.modal.restart": "Restart",
    "form.result.modal.failed": "Test failed",
    "form.result.modal.success": "Test passed",
    "form.result.modal.end": "End learning path",
    "form.end": "Submit test"
  },
  "cz_CZ": {
    "register.user_learning_path": "Learning path",
    "register": "User registration",
    "register.first_name": "First name",
    "register.pin_code": "PIN Code",
    "register.last_name": "Last name",
    "register.user_type": "User type",
    "register.company_name": "Company",
    "register.user_type.employee": "Worker",
    "register.user_type.visitor": "Visitor",
    "course.induction": "Induction",
    "course.decomissioning": "Decomissioning",
    "register.birth_nation": "Birth nation",
    "register.birth_date": "Birth date",
    "register.activity": "Activity to be done in central",
    "register.referral": "Internal referrer",
    "register.visit_reason": "Visit reason",
    "register.video_already_seen": "Did you watched the video previously?",
    "register.save": "Save and proceed with the learning path",
    "register.contact.title": "Please specify your contact info to get the PIN code to continue",
    "register.contact.pin_insert.title": "Please insert the PIN code you just received (by e-mail or SMS depending on your choice in the previous step)",
    "register.contact.type": "Contact type",
    "register.privacy": "I declare that I have carefully read the privacy policy provided pursuant to the national privacy legislation and art. 13 GDPR 679/2016 * e",
    "register.send_pin_code": "Send me the pin code",
    "register.contact.type.mail": "E-Mail address",
    "register.contact.type.cell_phone": "Cell phone",
    "course.summary": "Course summary",
    "general.user": "User",
    "general.yes": "Yes",
    "general.no": "No",
    "general.month.january": "January",
    "general.month.february": "February",
    "general.month.march": "March",
    "general.month.april": "April",
    "general.month.may": "May",
    "general.month.june": "June",
    "general.month.july": "July",
    "general.month.august": "August",
    "general.month.september": "September",
    "general.month.october": "October",
    "general.month.november": "November",
    "general.month.december": "December",
    "general.day": "Day",
    "general.month": "Month",
    "general.year": "Year",
    "general.pin_modal.title": "Verify access PIN",
    "general.pin_modal.description": "Please insert the PIN code you just received (by e-mail or SMS depending on your choice in the previous step) and click on \"Verify\" button.",
    "general.pin_modal.verify": "Verify",
    "general.path.start": "Start learning path",
    "general.path.play": "Play",
    "general.path.stop": "Stop",
    "general.path.next": "Next",
    "form.result.pass": "You passed the test, now you can download or print your rating certificate.",
    "form.result.notpass.medium": "You scored a percentage of _% correct answers. To pass the test you need to get a percentage of 100%, so you will have to answer the wrong questions again",
    "form.result.notpass.low": "You scored less than 70% correct answers, you will need to re-attend the entire course and take the final test again",
    "form.result.modal.retry": "Retry",
    "form.result.modal.certificate": "View/print certificate",
    "form.result.modal.restart": "Restart",
    "form.result.modal.failed": "Test failed",
    "form.result.modal.success": "Test passed",
    "form.result.modal.end": "End learning path",
    "form.end": "Submit test"
  },
};
