import React from "react";
import { useGlobalStateContext, setGlobalState } from "../context/GlobalContext";
import { Button } from "reactstrap";
import { Dictionary } from "../common/dictionary";

export default function PillsNavigation(props) {
  const { pillProgression } = useGlobalStateContext();
  const { participant } = useGlobalStateContext();

  let currentIndex = props.pills.findIndex((p) => { return p.id == pillProgression.currentPillId; });

  if (pillProgression.currentPillId === 0) {
    return (
      <div className="footer-controls">
        <Button style={{ backgroundColor: "#962033" }} onClick={() => { setGlobalState({ pillProgression: { currentPillId: props.pills[0].id, isComplete: false } }); }}>{Dictionary[participant.language]["general.path.start"]}</Button>
      </div>
    );
  } else {
    if (currentIndex + 1 <= props.pills.length) {
      // setGlobalState({ pillProgression: { currentPillId: props.pills[currentIndex + 1].id, isComplete: false } });
      return (
        <div className="footer-controls">
        </div>
      );
    }
  }
  return null;
}


