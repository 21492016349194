import React from "react";
import { setGlobalState, useGlobalStateContext } from "../context/GlobalContext";
import { Container, Row, Col, Modal, ModalHeader } from "reactstrap";
import { useHistory } from "react-router-dom";
import { DEBUG } from "../common/constants";

export default function LanguageChoice() {
  const { projectConfiguration } = useGlobalStateContext();
  const { participant } = useGlobalStateContext();
  const history = useHistory();

  const saveLanguageChoice = (languageCode) => {
    let part = participant;
    if (part == null)
      part = {};
    if (DEBUG) {
      part = {
        birth_date: "1977-01-02",
        company: "asdfasdf",
        contact: {
          completeInfo: true,
          detail: "emadesantis@gmail.com",
          detail_label: "register.contact.type.mail",
          type: "1"
        },
        firstName: "Emanuele",
        language: "it_IT",
        lastName: "Desantis",
        user_type: "1",
        user_learning_path: "1"
      };
    }

    part.language = languageCode;
    setGlobalState({ participant: part });

    history.push("/register");
  };

  const getLanguages = () => {
    return projectConfiguration.available_languages.map((l) => {
      return (
        <Col md="4" key={l.code}>
          <div className="language-block" onClick={() => saveLanguageChoice(l.code)}>
            <img className="img-choise" alt="" src={"img/" + (l.code) + ".png"} /><br />{l.description}
          </div>
        </Col>
      );
    });
  };

  return (
    <>
      <Container>
        <Row>
          <Col md="12">
            <h1 className="section-title">Scelta della lingua<br />Choice of the language</h1>
            <Row>{getLanguages()}</Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}
