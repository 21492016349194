import React, { useState, useEffect } from "react";
import { useGlobalStateContext } from "../context/GlobalContext";
import { Dictionary } from "../common/dictionary";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { BsFillCircleFill } from 'react-icons/bs';
import SpinnerComponent from "./SpinnerComponent";

export default function PathSummary(props) {
  const { projectConfiguration } = useGlobalStateContext();
  const { participant } = useGlobalStateContext();

  const {
    path
  } = props;

  const styleIcon = {
    color: "#962033"
  };

  if (projectConfiguration == null)
    return <SpinnerComponent />;

  return (
    <>
      <h1 className="section-title">{Dictionary[participant.language]["course.summary"]}</h1>
      <h2>{Dictionary[participant.language]["general.user"]}: <strong>{participant.firstName} {participant.lastName}</strong></h2>
      <Row>
        {(path != null ? path.pills.map((p) => {
          return (
            <Col md="12" key={p.id} style={{ marginBottom: "10px" }}>
              <Card>
                <CardBody>
                  <CardTitle>
                    <Row>
                      <span style={styleIcon}>
                        {(() => {
                          switch (p.type) {
                            case "embedded":
                              return <Col md="2"><BsFillCircleFill size={40} /></Col>;
                            case "video":
                              return <Col md="2"><BsFillCircleFill size={40} /></Col>;
                            case "test":
                              return <Col md="2"><BsFillCircleFill size={40} /></Col>;
                            default:
                              return <Col md="2"><BsFillCircleFill size={40} /></Col>;
                          }
                        })()}
                      </span>
                      <Col md="10" style={{ lineHeight: "40px" }}>
                        <strong>{p.title}</strong>
                        <span>{p.sommario}</span>
                      </Col>
                    </Row>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          );
        }) : "")}
      </Row>
    </>
  );
};
