import { COOKIE_ACCESS, DATE_LOCALE, DATE_TIME_LOCALE } from "./constants";
import Cookies from "universal-cookie";
import moment from "moment";

const cookie = new Cookies();

export function setAccessTokenCookie(token) {
    cookie.set(COOKIE_ACCESS, token);
};

export function getAccessTokenCookie() {
    return cookie.get(COOKIE_ACCESS);
};

export function removeAccessTokenCookie() {
    cookie.remove(COOKIE_ACCESS);
}

export function parseDateForFrontend(date) {
    return moment(date).format(DATE_LOCALE);
};

export function parseDateTimeForFrontend(date) {
    return moment(date).format(DATE_TIME_LOCALE);
};
