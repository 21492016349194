import React from 'react';
import ReactDOM from 'react-dom';
import { useCreateGlobalStateContext } from "./context/GlobalContext";
import * as serviceWorker from './serviceWorker';
import Proxy from './containers/Proxy';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/index.scss";
import "react-datepicker/dist/react-datepicker.css";

export function App() {
    const GlobalStateContext = useCreateGlobalStateContext({
        language: 'it_IT',
        projectConfiguration: null,
        participant: null,
        pillProgression: {
            currentPillId: 0,
            isComplete: false
        }
    });

    return (
        <GlobalStateContext>
            <React.StrictMode>
                <Proxy />
            </React.StrictMode>
        </GlobalStateContext>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
