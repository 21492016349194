export const BASE_API_URL = {
  DEV: "http://localhost:8080/api/v1",
  TEST: "http://formazione-impianti-api-test.eu-west-1.elasticbeanstalk.com/api/v1",
  PROD: "https://api-livornoferraris.releax.it/api/v1"
};

export const ENV = "PROD";
export const DEBUG = false;

export const API_URL = BASE_API_URL[ENV];
export const EMCS_RESOURCES_URL = "https://dvk6xo03h70cm.cloudfront.net/sites/1e5e44a5-4869-4570-8fd6-a70648e79baf";
export const PROJECT_GUID = "17fd4e4c-f591-40dc-9ff4-83908656356f";

export const PILL_TYPE = Object.freeze({
  VIDEO: "video",
  TEST: "test",
  EMBEDDED: "embedded"
});

export const COOKIE_ACCESS = "access_token_fs";
export const DATE_LOCALE = "DD/MM/YYYY";
export const DATE_TIME_LOCALE = "DD/MM/YYYY HH:mm";
export const PAGE_SIZE = 10;

export const REGEX = Object.freeze({
  PHONE: /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  EMAIL: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
});

export const ENDPOINTS = Object.freeze({
  "USER_REGISTER": API_URL + "/participant/register",
  "SEND_PIN": API_URL + "/pin/send",
  "COMPLETE_PATH": API_URL + "/participant/complete",
  "PRESIGNED_DOWNLOAD_URL": API_URL + "/asset/presigned-download-url",
  "DOWNLAOD_CERTIFICATE": API_URL + "/report/download",
  "LOGIN": API_URL + "/user/login",
  "DOWNLOAD_CSV_PARTICIPANTS": API_URL + "/admin/csv/list",
  "PARTICIPANTS_LIST": API_URL + "/admin/list/search",
  "PARTICIPANTS_LIST_COUNT": API_URL + "/admin/list/count",
  "DELETE_PARTICIPANT": API_URL + "/participant/delete"
});

export const THRESHOLD = {
  MAX: 100,
  MIN: 70
};
